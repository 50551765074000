import {apiClient} from './apiClient';
import {FormulationItemPayload, ProductPayload} from './productPayload';
import {Product} from '../models/Product';
import axios from 'axios';

export const productsApi = {
  getProducts: async (): Promise<Product[]> => {
    try {
      const response = await apiClient.get<Product[]>('/products/list');

      return response.data;
    } catch (error) {
      console.log('error', error);
      return [];
    }
  },
  getFormulationProducts: async (): Promise<Product[]> => {
    try {
      const response = await apiClient.get<Product[]>('/products/list-formulation');

      return response.data;
    } catch (error) {
      console.log('error', error);
      return [];
    }
  },
  getFormulationList: async (): Promise<Product[]> => {
    try {
      const response = await apiClient.get<Product[]>('/formulas/list');

      return response.data;
    } catch (error) {
      console.log('error', error);
      return [];
    }
  },
  getPdfFormula: async (id: string): Promise<Blob | null> => {
    try {
      const response = await apiClient.get(`/pdf/formula/${id}`, {
        responseType: 'arraybuffer',
      });
      return new Blob([response.data], {type: 'application/pdf'});
    } catch (error) {
      console.log('error', error);
      return null;
    }
  },
  getMixCode: async (): Promise<{mixCode: number; version: string}> => {
    try {
      const response = await apiClient.get<{mixCode: number; version: string}>('/formulas/code');

      return response.data;
    } catch (error) {
      console.log('error', error);
      return {mixCode: 0, version: ''};
    }
  },
  getVersion: async (): Promise<{version: string}> => {
    try {
      const response = await apiClient.get<{version: string}>('/formulas/version');

      return response.data;
    } catch (error) {
      console.log('error', error);
      return {version: ''};
    }
  },
  getFormulaById: async (id: string): Promise<Product> => {
    try {
      const response = await apiClient.get<Product>(`/formulas/${id}`);

      return response.data;
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  },
  // getProducts: async (): Promise<Product[]> => {
  //   try {
  //     const response = await apiClient.get<Product[]>('/products/list');
  //     const originalData = response.data;
  //     const replicatedData = [];
  //
  //     for (let i = 0; i < 10000; i++) {
  //       replicatedData.push(
  //         ...originalData.map((product) => ({
  //           ...product,
  //           umbrellaId: `${product.umbrellaId}-${i}`,
  //           name: `${product.name} - Replicado ${i}`,
  //         })),
  //       );
  //     }
  //
  //     return replicatedData;
  //   } catch (error) {
  //     console.log('error', error);
  //     throw error;
  //   }
  // },
  setProduct: async (product: Partial<ProductPayload>): Promise<Product> => {
    try {
      const response = await apiClient.post<Product>('/products', product);

      return response.data;
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  },
  setFormulation: async (params: {
    dosageValue: number;
    missingDosageValue: number;
    dosageName: string;
    price: number;
    ingredients: FormulationItemPayload[];
    mixType: string;
    mixCode: string;
    mixName: string;
    version: string;
    appearance: string;
    overdosage: boolean;
    controlRelease: boolean;
    shelfLifeMix: string;
    date: string;
    primaryPackaging: string;
    secondaryPackaging: string;
    terciaryPackaging: string;
    shelfLifeReal: string;
    shelfLifeEstimated: string;
    serving: string;
    dosageToDisolveIn: string;
    ph: string;
    idCustomer: string;
    customer: string;
    mesophilic: string;
    enterobacteriaceae: string;
    escherichia: string;
    salmonella: string;
    yeast: string;
    bulkDensity: string;
    humidity: string;
    solubility: string;
    refractiveIndex: string;
    poliarimetry: string;
    particleSize200: string;
    particleSize355: string;
    particleSize500: string;
    particleSizeMoreThan500: string;
    flashPoint: string;
    alcoholicGrade: string;
    identification: string;
  }): Promise<any> => {
    try {
      const response = await apiClient.post<Product>('/formulas', {
        dosageValue: params.dosageValue,
        missingDosageValue: params.missingDosageValue,
        dosageName: params.dosageName,
        price: params.price,
        ingredients: params.ingredients,
        mixType: params.mixType,
        mixCode: params.mixCode,
        mixName: params.mixName,
        version: params.version,
        overdosage: params.overdosage,
        controlRelease: params.controlRelease,
        shelfLifeMix: params.shelfLifeMix,
        date: params.date,
        primaryPackaging: params.primaryPackaging,
        secondaryPackaging: params.secondaryPackaging,
        terciaryPackaging: params.terciaryPackaging,
        shelfLifeReal: params.shelfLifeReal,
        shelfLifeEstimated: params.shelfLifeEstimated,
        serving: params.serving,
        appearance: params.appearance,
        dosageToDisolveIn: params.dosageToDisolveIn,
        ph: params.ph,
        idCustomer: params.idCustomer,
        customer: params.customer,
        mesophilic: params.mesophilic,
        enterobacteriaceae: params.enterobacteriaceae,
        escherichia: params.escherichia,
        salmonella: params.salmonella,
        yeast: params.yeast,
        bulkDensity: params.bulkDensity,
        humidity: params.humidity,
        solubility: params.solubility,
        refractiveIndex: params.refractiveIndex,
        poliarimetry: params.poliarimetry,
        particleSize200: params.particleSize200,
        particleSize355: params.particleSize355,
        particleSize500: params.particleSize500,
        particleSizeMoreThan500: params.particleSizeMoreThan500,
        flashPoint: params.flashPoint,
        alcoholicGrade: params.alcoholicGrade,
        identification: params.identification,
      });

      return response.data;
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  },
  editFormulation: async (params: {
    new_mixcode: boolean;
    dosageValue: number;
    missingDosageValue: number;
    dosageName: string;
    price: number;
    ingredients: FormulationItemPayload[];
    mixType: string;
    mixCode: string;
    mixName: string;
    version: string;
    appearance: string;
    overdosage: boolean;
    controlRelease: boolean;
    shelfLifeMix: string;
    date: string;
    primaryPackaging: string;
    secondaryPackaging: string;
    terciaryPackaging: string;
    shelfLifeReal: string;
    shelfLifeEstimated: string;
    serving: string;
    dosageToDisolveIn: string;
    ph: string;
    idCustomer: string;
    customer: string;
    mesophilic: string;
    enterobacteriaceae: string;
    escherichia: string;
    salmonella: string;
    yeast: string;
    bulkDensity: string;
    humidity: string;
    solubility: string;
    refractiveIndex: string;
    poliarimetry: string;
    particleSize200: string;
    particleSize355: string;
    particleSize500: string;
    particleSizeMoreThan500: string;
    flashPoint: string;
    alcoholicGrade: string;
    identification: string;
    id: string;
  }): Promise<any> => {
    try {
      const response = await apiClient.post<Product>(`/formulas/${params.id}/update`, {
        new_mixcode: params.new_mixcode,
        dosageValue: params.dosageValue,
        missingDosageValue: params.missingDosageValue,
        dosageName: params.dosageName,
        price: params.price,
        ingredients: params.ingredients,
        mixType: params.mixType,
        mixCode: params.mixCode,
        mixName: params.mixName,
        version: params.version,
        overdosage: params.overdosage,
        controlRelease: params.controlRelease,
        shelfLifeMix: params.shelfLifeMix,
        date: params.date,
        primaryPackaging: params.primaryPackaging,
        secondaryPackaging: params.secondaryPackaging,
        terciaryPackaging: params.terciaryPackaging,
        shelfLifeReal: params.shelfLifeReal,
        shelfLifeEstimated: params.shelfLifeEstimated,
        serving: params.serving,
        appearance: params.appearance,
        dosageToDisolveIn: params.dosageToDisolveIn,
        ph: params.ph,
        idCustomer: params.idCustomer,
        customer: params.customer,
        mesophilic: params.mesophilic,
        enterobacteriaceae: params.enterobacteriaceae,
        escherichia: params.escherichia,
        salmonella: params.salmonella,
        yeast: params.yeast,
        bulkDensity: params.bulkDensity,
        humidity: params.humidity,
        solubility: params.solubility,
        refractiveIndex: params.refractiveIndex,
        poliarimetry: params.poliarimetry,
        particleSize200: params.particleSize200,
        particleSize355: params.particleSize355,
        particleSize500: params.particleSize500,
        particleSizeMoreThan500: params.particleSizeMoreThan500,
        flashPoint: params.flashPoint,
        alcoholicGrade: params.alcoholicGrade,
        identification: params.identification,
      });

      return response.data;
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  },
  editProduct: async (product: Partial<ProductPayload>, id: string): Promise<Product> => {
    try {
      const response = await apiClient.put<any>(`/products/${id}/update`, product);

      return response.data;
    } catch (error) {
      console.log('error', error);
      throw error;
    }
  },
};
