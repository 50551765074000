import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Spin,
  Table,
  TableColumnType,
  Tag,
  Tooltip,
} from 'antd';
import {PlusCircleOutlined, MinusCircleOutlined, SearchOutlined} from '@ant-design/icons';
import {ColumnsType} from 'antd/es/table';
import {FilterDropdownProps} from 'antd/es/table/interface';
import {productsApi} from '../apiCalls/productsApi';
import {
  calculateMgPlus,
  calculateMissingMg,
  calculatePercentageFormula,
  calculatePercentageVRN,
} from '../helper/formulas';
import SelectComponent from '../components/Select';
import {FormulationItemPayload} from '../apiCalls/productPayload';
import {UpOutlined, DownOutlined} from '@ant-design/icons';
import SelectPackaging from '../components/SelectPackaging';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

interface FormulationItem {
  id: string;
  umbrellaId: string;
  claim: string;
  name: string;
  functionality: string;
  quantity?: number;
  vrn?: number;
  unitsClaim?: string;
  vrnUnit?: string;
  overdosage?: number;
  mgPlus?: number;
  formulaPercentage?: number;
  vrnPercentage?: number | string;
  limitSolids?: number;
  limitLiquids?: number;
  limitSyrupGels?: number;
  rdi?: number;
  mainActiveValue?: number;
  locked: boolean;
  declarableClaim: boolean;
  mainActiveDeclarable: boolean;
  withoutActives: boolean;
}

interface IngredientItem {
  id: string;
  umbrellaId: string;
  claim: string;
  name: string;
  functionality: string;
  mainActiveValue: number;
}

const commonCellProps = () => ({
  style: {padding: 5},
});

const EditFormulation: React.FC = () => {
  const [allIngredients, setAllIngredients] = useState<IngredientItem[]>([]);
  const [formulationIngredients, setFormulationIngredients] = useState<FormulationItem[]>([]);
  console.log('formulationIngredients', formulationIngredients);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [mixName, setmixName] = useState('');
  const [dosage, setDosage] = useState(1000);
  const [missingMg, setMissingMg] = useState(1000);
  const [mixType, setMixType] = useState('');
  const [formulationId, setFormulationId] = useState('');

  const [searchText, setSearchText] = useState('');
  const [mixCode, setMixCode] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [columns, setColumns] = useState<ColumnsType<FormulationItem>>([]);

  const [initialValues, setInitialValues] = useState<any>(null);

  const [form] = Form.useForm();
  const searchInput = useRef<any>(null);
  const navigate = useNavigate();
  const location = useLocation();
  // const {initialValues} = location.state || {};

  const requiredField = [
    {
      required: true,
      message: ``,
    },
  ];
  const {id} = useParams<{id: string}>();

  useEffect(() => {
    if (!initialValues) return;

    form.setFieldValue('mixCode', mixType + initialValues?.mixCode);
  }, [mixType, mixCode, initialValues]);

  useEffect(() => {
    if (!id) return;

    const getFormulation = async () => {
      const formulationRes = await productsApi.getFormulaById(id);
      console.log('res', formulationRes);
      setInitialValues(formulationRes);
    };

    getFormulation();
  }, [id]);

  useEffect(() => {
    if (allIngredients.length === 0) return;
    console.log('initialValues', initialValues);
    const items = initialValues.ingredients.map((ingredient: any) => {
      ingredient.product.quantity = ingredient.quantity;
      ingredient.product.unitsClaim = ingredient.unitsClaim;
      ingredient.product.functionality = ingredient.product.functionality?.name;
      ingredient.product.claim = ingredient.product.claim?.name;
      ingredient.product.overdosage = ingredient.overdosage;
      ingredient.product.vrnPercentage = ingredient.percentVrn;
      ingredient.product.mgPlus = ingredient.mgPlus;
      ingredient.product.formulaPercentage = ingredient.percentFormula;
      return ingredient.product;
    });
    console.log('debug items', items);
    setFormulationIngredients(items);
  }, [allIngredients]);

  useEffect(() => {
    setMixType(initialValues?.mixType);
  }, [initialValues]);

  useEffect(() => {
    const getVersion = async () => {
      const versionRes = await productsApi.getVersion();
      form.setFieldValue('version', versionRes.version);

      // setMixCode(versionRes.version);
    };
    getVersion();
  }, []);

  const handleSearch = (selectedKeys: string[], confirm: FilterDropdownProps['confirm'], dataIndex: string) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: string): TableColumnType<any> => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
      <div style={{padding: 8}} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{width: 90}}
          >
            Buscar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              confirm({closeDropdown: false});
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
              close();
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{color: filtered ? '#1677ff' : undefined}} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const selectIngredientColumn: ColumnsType<IngredientItem> = [
    {
      title: 'ID',
      dataIndex: 'umbrellaId',
      ...getColumnSearchProps('umbrellaId'),
    },
    {
      title: 'Claim',
      dataIndex: 'claim',
      ...getColumnSearchProps('claim'),
    },
    {
      title: 'Description',
      dataIndex: 'name',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Functionality',
      dataIndex: 'functionality',
      ...getColumnSearchProps('functionality'),
    },
    {
      title: '',
      dataIndex: 'add',
      render: (value, item) => (
        <a
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setFormulationIngredients((prevItems) => [...prevItems, item]);
            setIsModalVisible(false);
          }}
        >
          <PlusCircleOutlined style={{fontSize: 18}} />
        </a>
      ),
    },
  ];

  useEffect(() => {
    fetchItems();
  }, []);

  const renderForm = (value: any, item: any) => {
    if (typeof value === 'boolean') {
      return <Checkbox checked={value} />;
    }

    if (value?.name) {
      return value.name;
    }

    if (Array.isArray(value) && value.length > 0) {
      if (value[0]?.name) {
        // Si los elementos del array tienen una propiedad 'name', muestra un tooltip para cada uno
        const toolTipContent = value.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '1000px',
              }}
            >
              {item?.name?.length > 20 ? item?.name?.substring(0, 18) + '...' : item?.name}
            </div>
          );
        });

        return (
          <Tooltip title={<span>{toolTipContent}</span>} overlayStyle={{maxWidth: 1000}}>
            <Tag color="blue">{value.length === 1 ? 'Hay 1 valor' : `Hay ${value.length} valores`}</Tag>
          </Tooltip>
        );
      } else {
        if (item === 'actives') {
          const toolTipContent = value.map((item, index) => {
            const text = `•${item?.activeName?.name} ${item.activeValue}%`;
            return (
              <div
                key={index}
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '1000px',
                }}
              >
                {text}
              </div>
            );
          });

          return (
            <Tooltip title={<span>{toolTipContent}</span>} overlayStyle={{maxWidth: 1000}}>
              <Tag color="blue" key={item}>
                {value.length === 1 ? 'Hay 1 valor' : `Hay ${value.length} valores`}
              </Tag>
            </Tooltip>
          );
        }

        return (
          <Tag color="blue" key={item}>
            {value.length === 1 ? 'Hay 1 valor' : `Hay ${value.length} valores`}
          </Tag>
        );
      }
    }

    return value;
  };

  useEffect(() => {
    console.log('debug allIngredients', allIngredients);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const allKeys = [...new Set(allIngredients.flatMap(Object.keys))].filter((key) => key !== 'id');
    console.log('allKeys', allKeys);
    console.log('allIngredients', allIngredients);
    const createColumns: ColumnsType<FormulationItem> = [
      {
        title: '',
        dataIndex: 'remove',
        render: (value, item) => (
          <a
            onClick={() => {
              handleDelete(item.id);
            }}
          >
            <MinusCircleOutlined style={{fontSize: 18}} />
          </a>
        ),
      },
      {
        title: 'ID',
        dataIndex: 'umbrellaId',
      },
      {
        title: 'Claim',
        dataIndex: 'claim',
      },
      {
        title: 'Description',
        dataIndex: 'name',
      },
      {
        title: 'Functionality',
        dataIndex: 'functionality',
      },

      {
        title: '% Main Active',
        dataIndex: 'mainActiveValue',
      },
      {
        title: 'Quantity (Q) Claim',
        dataIndex: 'quantity',
        render: (text, record) => (
          <Input
            type="number"
            step={0.001}
            value={text}
            style={{minWidth: 100}}
            onChange={(e) => handleFieldChange(e.target.value, record.id, 'quantity')}
          />
        ),
        onCell: commonCellProps,
      },
      {
        title: 'Units claim',
        dataIndex: 'unitsClaim',
        render: (text, record) => (
          <Select
            defaultValue={text}
            onChange={(value) => {
              handleFieldChange(value, record.id, 'unitsClaim');
            }}
            style={{width: '100%'}}
          >
            <Select.Option value={'mg'}>mg</Select.Option>
            <Select.Option value={'ug'}>ug</Select.Option>
          </Select>
        ),
        onCell: commonCellProps,
      },
      {
        title: '% Overdosage',
        dataIndex: 'overdosage',
        render: (text, record) => (
          <Input
            type="number"
            step={0.1}
            value={text}
            onChange={(e) => handleFieldChange(e.target.value, record.id, 'overdosage')}
          />
        ),
        onCell: commonCellProps,
      },
      {
        title: 'Main Active Name',
        dataIndex: 'mainActiveName',
      },
      {
        title: 'Other actives',
        dataIndex: 'actives',
        render: (value: any) => renderForm(value, 'actives'),
      },
      {
        title: 'mg+',
        dataIndex: 'mgPlus',
        onCell: commonCellProps,
      },
      {
        title: '% Formula',
        dataIndex: 'formulaPercentage',
        onCell: commonCellProps,
      },
      {
        title: '% VRN',
        dataIndex: 'vrnPercentage',
        onCell: commonCellProps,
      },
      {
        title: '100% VRN',
        dataIndex: 'vrn',
        onCell: commonCellProps,
      },
      {
        title: 'VRN unit',
        dataIndex: 'vrnUnit',
        render: (text, record) => <p>{text ?? 'mg'}</p>,
        onCell: commonCellProps,
      },
      {
        title: 'Limit Solids',
        dataIndex: 'limitSolids',
        onCell: commonCellProps,
      },
      {
        title: 'Limit Liquids',
        dataIndex: 'limitLiquids',
        onCell: commonCellProps,
      },
      {
        title: 'Limit Syrup/Gels',
        dataIndex: 'limitSyrupGels',
        onCell: commonCellProps,
      },
      {
        title: 'Locked',
        dataIndex: 'locked',
        onCell: commonCellProps,
        render: (value, record) => (
          <Checkbox checked={value} onChange={(e) => handleFieldChange(e.target.checked, record.id, 'locked')} />
        ),
        // render: (value: any) => <Checkbox checked={value} />,
      },
      {
        title: 'main Active Declarable',
        dataIndex: 'mainActiveDeclarable',
        onCell: commonCellProps,
        render: (value, record) => (
          <Checkbox
            checked={value}
            onChange={(e) => handleFieldChange(e.target.checked, record.id, 'mainActiveDeclarable')}
          />
        ),
      },
      {
        title: 'Declarable Claim',
        dataIndex: 'declarableClaim',
        onCell: commonCellProps,
        render: (value, record) => (
          <Checkbox
            checked={value}
            onChange={(e) => handleFieldChange(e.target.checked, record.id, 'declarableClaim')}
          />
        ),
      },
      {
        title: 'without Actives',
        dataIndex: 'withoutActives',
        onCell: commonCellProps,
        render: (value, record) => (
          <Checkbox
            checked={value}
            onChange={(e) => handleFieldChange(e.target.checked, record.id, 'withoutActives')}
          />
        ),
      },
      {
        title: 'RDI',
        dataIndex: 'rdi',
        onCell: commonCellProps,
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...allKeys
        .filter(
          (item) =>
            ![
              'umbrellaId',
              'actives',
              'claim',
              'vrn',
              'name',
              'mainActiveName',
              'locked',
              'withoutActives',
              'declarableClaim',
              'mainActiveDeclarable',
              'vrnUnit',
            ].includes(item),
        )
        .map((item) => {
          return {
            title: item,
            key: item,
            dataIndex: item,
            width: 150,
            align: 'center',
            render: (value: any) => renderForm(value, item),
          };
        }),
    ];

    setColumns(createColumns);
  }, [allIngredients, formulationIngredients]);

  useEffect(() => {
    if (formulationIngredients.length === 0) {
      console.log('entro acaaaa');
      setMissingMg(dosage);
    } else {
      recalculateFormulation(formulationIngredients);
    }
  }, [formulationIngredients, dosage]);

  const handleFieldChange = (value: string | boolean, key: React.Key, column: keyof FormulationItem) => {
    const newData = updateFormulationData(value, key, column);
    recalculateFormulation(newData);
  };

  const updateFormulationData = (value: string | boolean, key: React.Key, column: keyof FormulationItem) => {
    const newData = [...formulationIngredients];
    const index = newData.findIndex((item) => item.id === key);
    const item = newData[index];
    if (item) {
      const newItem = {...item, [column]: value};
      newData.splice(index, 1, newItem);
    }
    return newData;
  };

  const recalculateFormulation = (newData: FormulationItem[]) => {
    newData.forEach((item) => {
      const newItem = {...item};
      newItem.mgPlus = calculateMgPlus(
        newItem.overdosage ?? 0,
        newItem.quantity,
        newItem.unitsClaim ?? 'mg',
        newItem.mainActiveValue,
      );

      const index = newData.findIndex((i) => i.id === newItem.id);
      newData.splice(index, 1, newItem);
    });

    const totalMg = newData.reduce((acc, item) => {
      return acc + Number(item.mgPlus);
    }, 0);

    newData.forEach((item) => {
      item.formulaPercentage = calculatePercentageFormula(item.mgPlus, totalMg);
      item.vrnPercentage = calculatePercentageVRN(
        item.quantity,
        item.vrn,
        item.unitsClaim ?? 'mg',
        item.vrnUnit ?? 'mg',
      );
    });

    const missingMgRes = calculateMissingMg(dosage, totalMg);
    setMissingMg(missingMgRes);

    setFormulationIngredients(newData);
  };

  const fetchItems = async () => {
    setLoading(true);
    try {
      const data = await productsApi.getFormulationProducts();
      const products = data
        .filter((item) => item.mainActiveValue)
        .map((item) => {
          return {
            ...item,
            name: item.name,
            id: item.id,
            umbrellaId: item.umbrellaId,
            functionality: item?.functionality?.name ?? '',
            claim: item?.claim?.name ?? '',
            mainActiveValue: item?.mainActiveValue ?? 0,
            vrn: item?.vrn ?? 0,
          };
        });
      setAllIngredients(products);
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (id: string) => {
    console.log('el id', id);
    setFormulationIngredients(formulationIngredients.filter((item) => item.id !== id));
  };

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    const hour = now.getHours().toString().padStart(2, '0');
    const minute = now.getMinutes().toString().padStart(2, '0');
    return `${year}${month}${day}${hour}${minute}`;
  };

  const submitFormulation = async (new_mixcode = false) => {
    if (formulationIngredients.length === 0) {
      notification.error({
        message: 'Required fields missing',
        description: 'You must fill out all required fields.',
        duration: 3,
      });
      return;
    }

    const formulationItems: FormulationItemPayload[] = formulationIngredients.map((ingredient) => ({
      quantity: ingredient.quantity ?? 0,
      unitsClaim: ingredient.unitsClaim ?? 'mg',
      overdosage: ingredient.overdosage ?? 0,
      mgPlus: ingredient.mgPlus ?? 0,
      percentFormula: ingredient.formulaPercentage ?? 0,
      percentVrn: ingredient.vrnPercentage ?? 0,
      limitSolids: ingredient.limitSolids ?? 0,
      limitLiquids: ingredient.limitLiquids ?? 0,
      limitSyrups: ingredient.limitSyrupGels ?? 0,
      rdi: ingredient.rdi ?? 0,
      product: ingredient.id,
      locked: ingredient.locked,
      declarableClaim: ingredient.declarableClaim,
      mainActiveDeclarable: ingredient.mainActiveDeclarable,
      withoutActives: ingredient.withoutActives,
    }));

    const formValues = form.getFieldsValue();
    setIsLoading(true);
    await productsApi.editFormulation({
      new_mixcode: new_mixcode,
      ingredients: formulationItems,
      dosageValue: dosage,
      missingDosageValue: missingMg,
      dosageName: '',
      price: 0,
      mixType: mixType,
      mixCode: formValues.mixCode,
      mixName: formValues.mixName,
      version: formValues.version,
      overdosage: formValues.overdosage ?? true,
      controlRelease: formValues.controlRelease ?? true,
      shelfLifeMix: formValues.shelfLifeMix ?? '12',
      date: new Date().toDateString(),
      primaryPackaging: formValues.primaryPackaging?.id ? formValues.primaryPackaging?.id : formValues.primaryPackaging,
      secondaryPackaging: formValues.secondaryPackaging?.id
        ? formValues.secondaryPackaging?.id
        : formValues.secondaryPackaging,
      terciaryPackaging: formValues.terciaryPackaging?.id
        ? formValues.terciaryPackaging?.id
        : formValues.terciaryPackaging,
      shelfLifeReal: formValues.shelfLifeReal,
      shelfLifeEstimated: formValues.shelfLifeEstimated,
      serving: formValues.serving,
      dosageToDisolveIn: formValues.dosageToDisolveIn,
      ph: formValues.ph,
      idCustomer: formValues.idCustomer,
      customer: formValues.customer,
      mesophilic: formValues.mesophilic ?? '1000',
      enterobacteriaceae: formValues.enterobacteriaceae ?? '10',
      escherichia: formValues.escherichia ?? 'absent',
      salmonella: formValues.salmonella ?? 'absent',
      yeast: formValues.yeast ?? '100',
      bulkDensity: formValues.bulkDensity,
      appearance: formValues.appearance,
      humidity: formValues.humidity,
      solubility: formValues.solubility,
      refractiveIndex: formValues.refractiveIndex,
      poliarimetry: formValues.poliarimetry,
      particleSize200: formValues.particleSize200,
      particleSize355: formValues.particleSize355,
      particleSize500: formValues.particleSize500,
      particleSizeMoreThan500: formValues.particleSizeMoreThan500,
      flashPoint: formValues.flashPoint,
      alcoholicGrade: formValues.alcoholicGrade,
      identification: formValues.identification ?? 'FTIR Spectrum',
      id: initialValues.id,
    });
    setIsLoading(false);

    navigate('/view-all-formulation');

    notification.success({
      message: 'Operación exitosa',
      description: 'Tu operación se ha completado con éxito.',
      duration: 3,
    });
  };

  if (!initialValues) {
    return (
      <Spin style={{justifyContent: 'center', flex: 1, marginTop: 150}} tip="Loading" size="large">
        <div className="content" />
      </Spin>
    );
  }

  if (loading) {
    return (
      <Spin style={{justifyContent: 'center', flex: 1, marginTop: 150}} tip="Loading" size="large">
        <div className="content" />
      </Spin>
    );
  }

  const renderFormulaActives = () => {
    return (
      <div
        style={{
          marginTop: 20,
          marginLeft: 10,
          marginBottom: 10,
        }}
      >
        {Object.entries(initialValues?.formula_actives).map(([key, value]) => (
          <div key={key}>
            <strong>{key}:</strong> {value as React.ReactNode}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <Modal
        title={'Add ingredient'}
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
        }}
        width={'55%'}
        style={{flex: 1}}
        okButtonProps={{
          style: {
            display: 'none',
          },
        }}
      >
        <Table
          columns={selectIngredientColumn}
          dataSource={allIngredients.filter((ingredient) => {
            return !formulationIngredients.some((item) => item.umbrellaId === ingredient.umbrellaId);
          })}
        />
      </Modal>

      <Modal
        title="¿Desea genearar un nuevo mixCode para esta formulación?"
        open={isUpdateModalVisible}
        okText={'SI'}
        cancelText={'NO'}
        confirmLoading={isLoading}
        onOk={() => submitFormulation(true)}
        onCancel={() => submitFormulation(false)}
      >
        <p>Confirme si desea generar el nuevo mixCode</p>
      </Modal>

      <div style={{marginLeft: 50, marginTop: 50}}>
        <h2 style={{marginBottom: 20}}>Add New Formulation</h2>

        <Form
          onFinishFailed={() => {
            notification.error({
              message: 'Required fields missing',
              description: 'You must fill out all required fields.',
              duration: 3,
            });
          }}
          initialValues={initialValues}
          onFinish={() => setIsUpdateModalVisible(true)}
          form={form}
          style={{marginRight: 50}}
          layout={'vertical'}
        >
          <p style={{fontWeight: 'bold'}}>Portal del cliente</p>

          <Row style={{marginBottom: -15}} gutter={16}>
            <Col span={6}>
              <Form.Item name={'idCustomer'} label="ID customer">
                <Input placeholder="Enter ID customer" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item rules={requiredField} name={'customer'} label="customer">
                <Input placeholder="Enter customer" />
              </Form.Item>
            </Col>
          </Row>

          <p style={{fontWeight: 'bold'}}>Portal de MIX</p>

          <Row style={{marginBottom: -15}} gutter={16}>
            <Col span={6}>
              <Form.Item rules={requiredField} name={'mixType'} label="MIX Type">
                <Select
                  onChange={(value) => {
                    setMixType(value);
                  }}
                  style={{width: '100%'}}
                >
                  <Select.Option value={'00'}>Powder</Select.Option>
                  <Select.Option value={'99'}>Liquid</Select.Option>
                  <Select.Option value={'88'}>Maquila</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name={'mixCode'} label="MIX Code">
                <Input
                  // defaultValue={fullMixCode}
                  disabled={true}
                  // value={fullMixCode} // Use the state variable here
                  placeholder="Enter MIX Code"
                  onChange={(e) => setFormulationId(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item rules={requiredField} name={'mixName'} label="MIX Name">
                <Input
                  // required={true}
                  placeholder="Enter MIX Name"
                  // onChange={(e) => setmixName(e.target.value)}
                />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item name={'version'} label="Version">
                <Input disabled defaultValue={getCurrentDateTime()} />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{marginBottom: -15}} gutter={16}>
            <Col span={6}>
              <Form.Item name={'overdosage'} label="Overdosage">
                <Select
                  // defaultValue={true}
                  onChange={(value) => {
                    form.setFieldValue('overdosage', value);
                    // handleFieldChange(value, record.id, 'unitsClaim');
                  }}
                  style={{width: '100%'}}
                >
                  <Select.Option value={true}>Yes</Select.Option>
                  <Select.Option value={false}>no</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name={'shelfLifeMix'} label="Shelf Life Mix">
                <Input placeholder="Enter shelfLifeMix" defaultValue={'12'} addonAfter={'Months'} />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item name={'controlRelease'} label="Control Release">
                <Select
                  onChange={(value) => {
                    form.setFieldValue('controlRelease', value);
                    // handleFieldChange(value, record.id, 'unitsClaim');
                  }}
                  style={{width: '100%'}}
                >
                  <Select.Option value={true}>Yes</Select.Option>
                  <Select.Option value={false}>no</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <div>
            <p style={{fontWeight: 'bold'}}>Final Product info</p>
            <>
              <Row style={{marginBottom: -15}} gutter={16}>
                <Col span={6}>
                  <Form.Item rules={requiredField} name={'primaryPackaging'} label="Primary Packaging">
                    {mixType !== '' ? (
                      <SelectPackaging
                        title={'primaryPackaging'}
                        onChange={(value) => {
                          // setTodo(value);
                        }}
                        defaultValue={initialValues.primaryPackaging.id}
                        endpoint={`/lists/packaging/1/${mixType}`}
                        canDoPostRequest={true}
                        mixType={mixType}
                        pst={1}
                      />
                    ) : (
                      <Select />
                    )}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item rules={requiredField} name={'secondaryPackaging'} label="Secondary Packaging">
                    <SelectPackaging
                      title={'secondaryPackaging'}
                      onChange={(value) => {
                        // setTodo(value);
                      }}
                      defaultValue={initialValues.secondaryPackaging}
                      endpoint={'/lists/packaging/2/00'}
                      canDoPostRequest={true}
                      pst={2}
                      mixType={'00'}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item rules={requiredField} name={'terciaryPackaging'} label="Terciary Packaging">
                    <SelectPackaging
                      title={'terciaryPackaging'}
                      onChange={(value) => {
                        // setTodo(value);
                      }}
                      defaultValue={initialValues.terciaryPackaging}
                      endpoint={'/lists/packaging/3/00'}
                      canDoPostRequest={true}
                      pst={3}
                      mixType={'00'}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row style={{marginBottom: -15}} gutter={16}>
                <Col span={6}>
                  <Form.Item name={'shelfLifeReal'} label="Shelf Life Real">
                    <Input type="number" placeholder="Enter Shelf Life Real" addonAfter={'Months'} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item rules={requiredField} name={'shelfLifeEstimated'} label="Shelf Life Estimated">
                    <Input type="number" placeholder="Enter Shelf Life Estimated" addonAfter={'Months'} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item rules={requiredField} name={'serving'} label="Dairy Serving">
                    <Input addonAfter={'Shots'} type="number" placeholder="Enter dairy serving" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={'dosageToDisolveIn'} label="Dosage to dissolve in:">
                    <Input
                      addonAfter={'ml'}
                      type="number"
                      placeholder="Enter dosage to dissolve in"
                      // onChange={(e) => setDosageToDissolveIn(e.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row style={{marginBottom: -15}} gutter={16}>
                <Col span={6}>
                  <Form.Item name={'appearance'} label="Appearance:">
                    <Input
                      // addonAfter={'%'}
                      type="text"
                      placeholder="Enter appearance"
                      // onChange={(e) => setAppearancePercentage(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={'ph'} label="ph:">
                    <Input addonAfter={'50g/L'} type="number" placeholder="Enter pH value" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={'bulkDensity'} label="Bulk Density/Density:">
                    <Input addonAfter={'g/mL'} type="number" placeholder="Enter bulk density" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={'humidity'} label="Humidity:">
                    <Input addonAfter={'%'} type="number" placeholder="Enter humidity percentage" />
                  </Form.Item>
                </Col>
              </Row>

              <Row style={{marginBottom: -15}} gutter={16}>
                <Col span={6}>
                  <Form.Item name={'solubility'} label="Solubility:">
                    <Input addonAfter={'%'} type="number" placeholder="Enter solubility percentage" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={'refractiveIndex'} label="Refractive Index:">
                    <Input addonAfter={'°'} type="number" placeholder="Enter refractive index" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={'poliarimetry'} label="Polarity:">
                    <Input addonAfter={'°'} type="number" placeholder="Enter polarity" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={'particleSize200'} label="Particle Size <200 ug:">
                    <Input addonAfter={'%'} type="number" placeholder="Enter particle size <200 ug" />
                  </Form.Item>
                </Col>
              </Row>

              <Row style={{marginBottom: -15}} gutter={16}>
                <Col span={6}>
                  <Form.Item name={'particleSize355'} label="Particle Size <355 ug:">
                    <Input addonAfter={'%'} type="number" placeholder="Enter particle size <355 ug" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={'particleSize500'} label="Particle Size < 500 ug:">
                    <Input addonAfter={'°'} type="number" placeholder="Enter Particle Size < 500 ug" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={'particleSizeMoreThan500'} label="Particle Size > 500 ug:">
                    <Input addonAfter={'°'} type="number" placeholder="Enter Particle Size > 500 ug" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={'flashPoint'} label="Flash Point:">
                    <Input addonAfter={'º'} type="number" placeholder="Enter flash point" />
                  </Form.Item>
                </Col>
              </Row>

              <Row style={{marginBottom: -15}} gutter={16}>
                <Col span={6}>
                  <Form.Item name={'alcoholicGrade'} label="Alcoholic Grade">
                    <Input addonAfter={'º'} type="number" placeholder="Enter alcoholic grade" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={'identification'} label="Identification:">
                    <Select
                      onChange={(value) => {
                        form.setFieldValue('identification', value);
                      }}
                      defaultValue={'FTIR Spectrum'}
                      style={{width: '100%'}}
                    >
                      <Select.Option value={'FTIR Spectrum'}>FTIR Spectrum</Select.Option>
                      <Select.Option value={'HPLC'}>HPLC</Select.Option>
                      <Select.Option value={'Organoleptic Panel'}>Organoleptic Panel</Select.Option>
                      <Select.Option value={'Weight Protocol'}>Weight Protocol</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </>

            <p style={{fontWeight: 'bold'}}>Microbiology</p>

            <Row style={{marginBottom: -15}} gutter={16}>
              <Col span={6}>
                <Form.Item name={'mesophilic'} label="Mesophilic aerobics ≤">
                  <Input defaultValue={1000} addonAfter={'cfu/g'} type="number" placeholder="Enter ≤ 1000 cfu/g" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name={'enterobacteriaceae'} label="T. Enterobacteriaceae ≤">
                  <Input defaultValue={10} addonAfter={'cfu/g'} type="number" placeholder="Enter ≤ 10 cfu/g" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name={'escherichia'} label="Escherichia Coli">
                  <Input defaultValue={'absent'} addonAfter={'/g'} type="text" placeholder="g" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name={'salmonella'} label="Salmonella SPP">
                  <Input defaultValue={'absent'} addonAfter={'/25g'} type="text" placeholder="Enter Salmonella SPP" />
                </Form.Item>
              </Col>
            </Row>

            <Row style={{marginBottom: -15}} gutter={16}>
              <Col span={6}>
                <Form.Item name={'yeast'} label="T. Yeast & Moulds ≤">
                  <Input defaultValue={100} addonAfter={'cfu/g'} type="number" placeholder="Enter T. Yeast & Moulds" />
                </Form.Item>
              </Col>
            </Row>

            <Divider
              orientation="left"
              orientationMargin={0}
              style={{
                marginBottom: 15,
                marginTop: 20,
              }}
            >
              {/*{section.title}*/}
            </Divider>
            <Row style={{marginBottom: -15}} gutter={16}>
              <Col span={6}>
                <Form.Item label="Dosage (mg)">
                  <Input
                    type="number"
                    value={dosage}
                    onChange={(e) => setDosage(parseFloat(e.target.value))}
                    addonAfter={'mg'}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label={
                    <label style={{color: missingMg > 0 ? 'green' : missingMg < 0 ? 'red' : 'black'}}>
                      Missing (mg)
                    </label>
                  }
                >
                  <Input
                    value={missingMg}
                    style={{
                      borderColor: 'red',
                      borderWidth: 1,
                    }}
                    addonAfter={
                      <label style={{color: missingMg > 0 ? 'green' : missingMg < 0 ? 'red' : 'black'}}>mg</label>
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <div>{renderFormulaActives()}</div>
          </div>

          <Button
            style={{marginTop: 10}}
            onClick={() => {
              setIsModalVisible(true);
            }}
          >
            Add ingredient
            <PlusCircleOutlined style={{marginLeft: 15, fontSize: 15}} />
          </Button>

          <div style={{marginTop: 20, overflowX: 'auto', maxHeight: 800}}>
            <Table columns={columns} dataSource={formulationIngredients} loading={loading} />
          </div>
          <div style={{marginTop: 20, marginBottom: 20}}>
            <Button type="primary" htmlType="submit">
              Save formulation
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default EditFormulation;
