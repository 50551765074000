import {ColumnsType} from 'antd/es/table';
import React from 'react';
import {formConfig} from './productFormData';

// export const ingredientsColumn: ColumnsType<IngredientDataType> = [
//   {
//     title: 'ING Claim',
//     dataIndex: 'ingClaim',
//     key: 'ingClaim',
//     width: 70,
//     fixed: 'left',
//   },
//   {
//     title: 'Type',
//     dataIndex: 'type',
//     key: 'type',
//     width: 120,
//     fixed: 'left',
//   },
//   {
//     title: 'ID',
//     dataIndex: 'id',
//     key: 'id',
//     width: 70,
//     sorter: (a, b) => a.id - b.id,
//   },
//   {
//     title: 'Formula Ingredient Name',
//     dataIndex: 'formulaIngredientName',
//     key: 'formulaIngredientName',
//     width: 200,
//   },
//   {
//     title: 'Supplier Code',
//     dataIndex: 'supplierCode',
//     key: 'supplierCode',
//     width: 80,
//   },
//   {
//     title: 'Supplier',
//     dataIndex: 'supplier',
//     key: 'supplier',
//     width: 100,
//   },
//   {
//     title: '% Active1',
//     dataIndex: 'percentActive1',
//     key: 'percentActive1',
//     width: 100,
//   },
//   {
//     title: 'Preu1 Eur/kg',
//     dataIndex: 'preu1EurPerKg',
//     key: 'preu1EurPerKg',
//     width: 100,
//   },
//   {
//     title: '[mg] Claim',
//     dataIndex: 'mgClaim',
//     key: 'mgClaim',
//     width: 100,
//   },
//   {
//     title: 'nº Ing',
//     dataIndex: 'numIng',
//     key: 'numIng',
//     width: 100,
//   },
//   {
//     title: '%VRN',
//     dataIndex: 'percentVRN',
//     key: 'percentVRN',
//     width: 100,
//   },
//   {
//     title: '[%] OverD',
//     dataIndex: 'percentOverD',
//     key: 'percentOverD',
//     width: 100,
//   },
//   {
//     title: '(mg) Ing+',
//     dataIndex: 'mgIngPlus',
//     key: 'mgIngPlus',
//     width: 100,
//   },
//   {
//     title: '% Formula',
//     dataIndex: 'percentFormula',
//     key: 'percentFormula',
//     width: 100,
//   },
//   {
//     title: '%ToAlcvs%',
//     dataIndex: 'percentToAlcvs',
//     key: 'percentToAlcvs',
//     width: 100,
//   },
//   {
//     title: '%Humidityvs%',
//     dataIndex: 'percentHumidityvs',
//     key: 'percentHumidityvs',
//     width: 100,
//   },
//   {
//     title: '% vs',
//     dataIndex: 'percentVs',
//     key: 'percentVs',
//     width: 100,
//   },
//   {
//     title: '-vs',
//     dataIndex: 'minusVs',
//     key: 'minusVs',
//     width: 100,
//   },
//   {
//     title: '+vs',
//     dataIndex: 'plusVs',
//     key: 'plusVs',
//     width: 100,
//   },
//   {
//     title: 'Kcal /D',
//     dataIndex: 'kcalPerD',
//     key: 'kcalPerD',
//     width: 100,
//   },
//   {
//     title: 'KJ /D',
//     dataIndex: 'kjPerD',
//     key: 'kjPerD',
//     width: 100,
//   },
//   {
//     title: 'Protein(g)/D',
//     dataIndex: 'proteinPerD',
//     key: 'proteinPerD',
//     width: 100,
//   },
//   {
//     title: 'Carbohydrates(g)/D',
//     dataIndex: 'carbohydratesPerD',
//     key: 'carbohydratesPerD',
//     width: 100,
//   },
//   {
//     title: 'Sugars(g)/D',
//     dataIndex: 'sugarsPerD',
//     key: 'sugarsPerD',
//     width: 100,
//   },
//   {
//     title: 'Polyols (g)/D',
//     dataIndex: 'polyolsPerD',
//     key: 'polyolsPerD',
//     width: 100,
//   },
//   {
//     title: 'Starch(g)/D',
//     dataIndex: 'starchPerD',
//     key: 'starchPerD',
//     width: 100,
//   },
//   {
//     title: 'Fats(g)/D',
//     dataIndex: 'fatsPerD',
//     key: 'fatsPerD',
//     width: 100,
//   },
//   {
//     title: 'Fats Sat.(g)/D',
//     dataIndex: 'fatsSatPerD',
//     key: 'fatsSatPerD',
//     width: 100,
//   },
//   {
//     title: 'Mono-Unsat(g)/D',
//     dataIndex: 'monoUnsatPerD',
//     key: 'monoUnsatPerD',
//     width: 100,
//   },
//   {
//     title: 'Poly-Unsat(g)/D',
//     dataIndex: 'polyUnsatPerD',
//     key: 'polyUnsatPerD',
//     width: 100,
//   },
//   {
//     title: 'trans-Fat(g)/D',
//     dataIndex: 'transFatPerD',
//     key: 'transFatPerD',
//     width: 100,
//   },
//   {
//     title: 'Cholesterol (mg)/D',
//     dataIndex: 'cholesterolPerD',
//     key: 'cholesterolPerD',
//     width: 100,
//   },
//   {
//     title: '',
//     key: 'operation',
//     width: 100,
//     fixed: 'right',
//     render: () => <a>Edit</a>, // Esto puede ser un enlace o un botón que realiza una acción, como editar
//   },
// ];

export const ingredientsColumn: ColumnsType<any> = [];

formConfig.form.sections.forEach((section) => {
  section.fields.forEach((field) => {
    ingredientsColumn.push({
      title: field.label,
      dataIndex: field.label.replace(/\s+/g, '').toLowerCase(),
      key: field.label.replace(/\s+/g, '').toLowerCase(),
      width: 100,
    });
  });
});

export interface IngredientDataType {
  name?: string;
  ingClaim?: string;
  type?: string;
  id: number;
  formulaIngredientName?: string;
  supplierCode?: string;
  supplier?: string;
  percentActive1?: number;
  preu1EurPerKg?: number;
  mgClaim?: number;
  numIng?: number;
  percentVRN?: number;
  percentOverD?: number;
  mgIngPlus?: number;
  percentFormula?: number;
  percentToAlcvs?: number;
  percentHumidityvs?: number;
  percentVs?: number;
  minusVs?: number;
  plusVs?: number;
  kcalPerD?: number;
  kjPerD?: number;
  proteinPerD?: number;
  carbohydratesPerD?: number;
  sugarsPerD?: number;
  polyolsPerD?: number;
  starchPerD?: number;
  fatsPerD?: number;
  fatsSatPerD?: number;
  monoUnsatPerD?: number;
  polyUnsatPerD?: number;
  transFatPerD?: number;
  cholesterolPerD?: number;
  [key: string]: any; // para propiedades adicionales no definidas
}

interface IngredientItem {
  [key: string]: any;
}

export const ingredientItem: IngredientItem = {};

formConfig.form.sections.forEach((section) => {
  section.fields.forEach((field) => {
    const key = field.label.replace(/\s+/g, '').toLowerCase();

    switch (field.type) {
      case 'textInputNumber':
        ingredientItem[key] = 123;
        break;
      case 'textInputString':
        ingredientItem[key] = 'Texto de ejemplo';
        break;
      case 'checkbox':
        ingredientItem[key] = true;
        break;
      case 'selector':
        ingredientItem[key] = 'Test';
        break;
      default:
        ingredientItem[key] = 'Valor por defecto';
        break;
    }
  });
});

// export const ingredientItem = {
//   ingClaim: 'A',
//   type: 'Functional',
//   formulaIngredientName: 'Beta-carotene 10%',
//   supplierCode: '1',
//   supplier: 'BTC Chemical Distribution 1',
//   percentActive1: 10,
//   preu1EurPerKg: 142,
//   mgClaim: 0,
//   numIng: 2,
//   percentVRN: 0,
//   percentOverD: 0,
//   mgIngPlus: 0,
//   percentFormula: 0,
//   percentToAlcvs: 10,
//   percentHumidityvs: 0,
//   percentVs: 0,
//   minusVs: 0,
//   plusVs: 0,
//   kcalPerD: 0,
//   kjPerD: 0,
//   proteinPerD: 0,
//   carbohydratesPerD: 0,
//   sugarsPerD: 0,
//   polyolsPerD: 0,
//   starchPerD: 0,
//   fatsPerD: 0,
//   fatsSatPerD: 0,
//   monoUnsatPerD: 0,
//   polyUnsatPerD: 0,
//   transFatPerD: 0,
//   cholesterolPerD: 0,
// };
