import React, {useState, useEffect} from 'react';
import {Checkbox, Form, FormInstance, Spin} from 'antd';
import {selectorItemsApi} from '../apiCalls/selectorItemsApi';
import {SelectorItem} from '../apiCalls/selectorItems';

export interface CheckboxListOneOptionProps {
  onChange?: (value: string) => void;
  endpoint: string;
  name?: string;
  form: FormInstance<any>;
}

const CheckboxListOneOption: React.FC<CheckboxListOneOptionProps> = ({onChange, endpoint, form, name}) => {
  const [options, setOptions] = useState<SelectorItem[]>([]);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  useEffect(() => {
    if (form.getFieldValue(name)) {
      const value = form.getFieldValue(name);
      setSelectedOption(value);
    }
  }, [options]);

  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = async () => {
    try {
      const response = await selectorItemsApi.getItemList(endpoint);
      setOptions(response);
    } catch (error) {
      console.error('Error fetching options:', error);
    }
  };

  const handleChange = (e: any) => {
    const value = e.target.value;
    setSelectedOption(value);
    form.setFieldsValue({type: value});

    if (onChange) {
      onChange(value);
    }
  };

  // TODO CAMBIAR 'type' POR VALOR DINAMICO DEL FORM
  return (
    <Form.Item name={'type'} style={{marginTop: 30}}>
      {options.map((item) => (
        <Checkbox key={item.id} value={item.name} checked={selectedOption === item.name} onChange={handleChange}>
          {item.name}
        </Checkbox>
      ))}
    </Form.Item>
  );
};

export default CheckboxListOneOption;
