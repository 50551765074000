import React, {useState} from 'react';
import {Form, Input, Button, Card, notification} from 'antd';
import {MailOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import './Login.css';

interface ForgotPasswordValues {
  email: string;
}

const ForgotPasswordScreen = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const onFinish = async (values: ForgotPasswordValues) => {
    setLoading(true);
    try {
      setTimeout(() => {
        setLoading(false);

        navigate('/login');
        notification.success({
          message: 'Operación exitosa',
          description: 'Se ha enviado un email para reiniciar su contraseña',
          duration: 3,
        });
      }, 1000);
    } catch (error) {
      console.error('Forgot password error:', error);
      setLoading(false);
      setErrorMessage('An error occurred during password reset. Please try again.');
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#0494ac',
      }}
    >
      <Card>
        <Form name="forgot_password" className="forgot-password-form" onFinish={onFinish}>
          <Form.Item>
            <center>
              <img src={require('../assets/umbrella-logo.png')} alt="Logo" style={{width: '55%'}} />
            </center>
          </Form.Item>

          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please input your E-mail!',
              },
            ]}
          >
            <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="forgot-password-form-button" block loading={loading}>
              Reiniciar contraseña
            </Button>
            {errorMessage && <p style={{color: 'red'}}>{errorMessage}</p>}
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default ForgotPasswordScreen;
