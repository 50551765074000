import {User} from './apiCalls/auth';

const USER = 'user';
const AUTH_TOKEN = 'authToken';
const REFRESH_TOKEN = 'refreshToken';

export class LocalStorage {
  static clearAll(): void {
    return localStorage.clear();
  }

  static setUser(user: User): void {
    return localStorage.setItem('user', JSON.stringify(user));
  }

  static getUser(): User | null {
    try {
      const savedUser = localStorage.getItem('user');

      return savedUser ? JSON.parse(savedUser) : null;
    } catch (e) {
      return null;
    }
  }

  static getAuthToken(): string | null {
    return localStorage.getItem(AUTH_TOKEN);
  }

  static setAuthToken(authToken: string): void {
    return localStorage.setItem(AUTH_TOKEN, authToken);
  }

  static setRefreshToken(refreshToken: string): void {
    return localStorage.setItem(REFRESH_TOKEN, refreshToken);
  }

  static getRefreshToken(): string | null {
    return localStorage.getItem(REFRESH_TOKEN);
  }
}
