import React, {createContext, useContext, useState, ReactNode} from 'react';
import {User} from './apiCalls/auth';
import {LocalStorage} from './LocalStorage';

interface UserContextType {
  user: User | null;
  setLoginUser: (userData: User) => void;
  logoutUser: () => void;
}

const UserContext = createContext<UserContextType | null>(null);

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider = ({children}: UserProviderProps) => {
  const [user, setUser] = useState<User | null>(() => {
    return LocalStorage.getUser();
  });

  const setLoginUser = (userData: User) => {
    setUser(userData);
    LocalStorage.setUser(userData);
  };

  const logoutUser = () => {
    setUser(null);
    LocalStorage.clearAll();
  };

  return <UserContext.Provider value={{user, setLoginUser, logoutUser}}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
