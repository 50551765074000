import {apiClient} from './apiClient';
import {LoginCredentials, LoginResponse} from './auth';
import {LocalStorage} from '../LocalStorage';

export const authApi = {
  login: async (credentials: LoginCredentials): Promise<LoginResponse> => {
    try {
      const response = await apiClient.post<LoginResponse>('/login', credentials);

      return response.data;
    } catch (error) {
      // LocalStorage.clearAll();
      console.log('error', error);
      throw error;
    }
  },
};
