import React from 'react';
import {HashRouter, Routes, Route, Navigate} from 'react-router-dom';
import LoginScreen from './screens/Login';
import ForgotPasswordScreen from './screens/ForgotPassword';
import NavBar from './components/NavBar';
import {Layout} from 'antd';
import AddIngredientForm from './screens/AddIngredientForm';
import Profile from './screens/Profile';
import Home from './screens/Home';
import Brands from './screens/Brands';
import Providers from './screens/Providers';
import CustomFormTable from './screens/CustomFormTable';
import EditIngredientForm from './screens/EditIngredientForm';
import AddFormulation from './screens/AddFormulation';
import FormulationList from './screens/FormulationList';
import EditFormulation from './screens/EditFormulation';
const {Content, Footer} = Layout;

const RequireAuth: React.FC<{
  children: JSX.Element;
}> = ({children}) => {
  const authToken = localStorage.getItem('authToken');
  if (!authToken) {
    return <Navigate to="/login" />;
  }

  return (
    <div>
      <NavBar />
      <Layout style={{display: 'flex', flexDirection: 'column', minHeight: '93vh'}}>
        <Content style={{flex: 1, marginTop: 64}}>{children}</Content>
      </Layout>
      <Footer style={{backgroundColor: '#99abc4', textAlign: 'right'}}>
        <div style={{fontSize: 11}}>Ümbrella Group ®</div>
      </Footer>
    </div>
  );
};

const protectedRoutes = [
  {path: '/', element: <Home />},
  {path: '/profile', element: <Profile />},
  {path: '/add-ingredient', element: <AddIngredientForm />},
  {path: '/edit-product', element: <EditIngredientForm />},
  {path: '/view-all-ingredients', element: <CustomFormTable />},
  {path: '/brands', element: <Brands />},
  {path: '/providers', element: <Providers />},
  {path: '/add-formulation', element: <AddFormulation />},
  {path: '/view-all-formulation', element: <FormulationList />},
  {path: '/edit-formulation/:id', element: <EditFormulation />},
];

const App: React.FC = () => {
  return (
    <HashRouter>
      <Routes>
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/forgot-password" element={<ForgotPasswordScreen />} />

        {protectedRoutes.map((route) => {
          return <Route key={route.path} path={route.path} element={<RequireAuth>{route.element}</RequireAuth>} />;
        })}
      </Routes>
    </HashRouter>
  );
};

export default App;
