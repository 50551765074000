import React from 'react';
import {useUser} from '../UserContext';

const Profile: React.FC = () => {
  const {user} = useUser();

  if (!user) {
    return null;
  }

  return (
    <div style={styles.container}>
      <h2>Profile</h2>
      <div>
        <p style={styles.info}>
          <strong>Name:</strong> {user.name}
        </p>
        <p style={styles.info}>
          <strong>Surname:</strong> {user.surname}
        </p>
        <p style={styles.info}>
          <strong>Email:</strong> {user.email}
        </p>
        <p style={styles.info}>
          <strong>Role:</strong> {user.primary_role}
        </p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '600px',
    margin: '0 auto',
    padding: '20px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    marginTop: '20px',
  },
  header: {
    textAlign: 'center',
    color: '#333',
  },
  info: {
    fontSize: '16px',
    lineHeight: 1.6,
    color: '#666',
  },
  loading: {
    textAlign: 'center',
    padding: '50px',
  },
};

export default Profile;
