import React, {useEffect, useState} from 'react';
import {Form, Input, Button, Card} from 'antd';
import {UserOutlined, LockOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import './Login.css';
import {authApi} from '../apiCalls/authApi';
import {LocalStorage} from '../LocalStorage';
import {useUser} from '../UserContext';

interface LoginValues {
  email: string;
  password: string;
  remember: boolean;
}

const LoginScreen = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const {setLoginUser} = useUser();

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      navigate('/', {replace: true});
    }
  }, [navigate]);

  const onFinish = async (values: LoginValues) => {
    setLoading(true);

    authApi
      .login({email: values.email, password: values.password})
      .then((response) => {
        LocalStorage.setAuthToken(response.data.auth_token);
        LocalStorage.setRefreshToken(response.data.refresh_token);

        setLoginUser(response.data.user);
        setLoading(false);
        navigate('/view-all-ingredients');
      })
      .catch((error) => {
        if (error.code === 'ERR_BAD_REQUEST') {
          setErrorMessage('Los datos no son correctos. Vuelva a intentar');
        } else {
          setErrorMessage('Ocurrió un error durante el inicio de sesión. Por favor, inténtalo de nuevo.');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#0494ac',
      }}
    >
      <Card>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
            email: 'diego@bake250.com',
            password: '123456',
          }}
          onFinish={onFinish}
        >
          <Form.Item>
            <center>
              <img src={require('../assets/umbrella-logo.png')} alt="Logo" style={{width: '55%'}} />
            </center>
          </Form.Item>

          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please input your E-mail!',
              },
            ]}
          >
            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your Password!',
              },
            ]}
          >
            <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Password" />
          </Form.Item>

          {/*<Form.Item>*/}
          {/*<Form.Item name="remember" valuePropName="checked" noStyle>*/}
          {/*  <Checkbox>Remember me</Checkbox>*/}
          {/*</Form.Item>*/}

          {/*<a*/}
          {/*  className="login-form-forgot"*/}
          {/*  onClick={() => {*/}
          {/*    navigate('/forgot-password');*/}
          {/*  }}*/}
          {/*  style={{float: 'right'}}*/}
          {/*>*/}
          {/*  Forgot password*/}
          {/*</a>*/}
          {/*</Form.Item>*/}

          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button" block loading={loading}>
              Log in
            </Button>
            {errorMessage && <p style={{color: 'red', minHeight: 30, textAlign: 'center'}}>{errorMessage}</p>}
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default LoginScreen;
