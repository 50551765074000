import axios, {AxiosResponse} from 'axios';
import {LocalStorage} from '../LocalStorage';

export const apiBaseUrl = 'https://api-dev.formix.cloud/api';

axios.defaults.baseURL = apiBaseUrl;
axios.defaults.timeout = 21000;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

export const apiClient = axios.create();

const UNAUTHORIZED_ENDPOINTS = ['/sign-in', '/auth_tokens', '/passwords/reset_token'];

function endpointRequiresAuthHeader(url: string): boolean {
  const urlNoQueryStrings = url.split('?')[0];
  return !UNAUTHORIZED_ENDPOINTS.includes(urlNoQueryStrings);
}

apiClient.interceptors.request.use(async (requestConfig) => {
  const url = requestConfig.url;
  if (url && endpointRequiresAuthHeader(url)) {
    const token = await LocalStorage.getAuthToken();
    if (token) {
      requestConfig.headers.Authorization = `Bearer ${token}`;
    }
  }
  return requestConfig;
});

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

const logout = () => {
  console.log('calling logout...');
  LocalStorage.clearAll();

  const protocol = window.location.protocol;
  const hostname = window.location.hostname;
  window.location.href = `${protocol}//${hostname}/#/login`;
};

interface RefreshTokenResponse {
  auth_token: string;
  refresh_token: string;
}

interface RefreshTokenResult {
  newAuthToken: string;
  newRefreshToken: string;
}

function refreshToken(currentRefreshToken: string): Promise<RefreshTokenResult> {
  return apiClient
    .post(`/auth_tokens`, {refresh_token: currentRefreshToken})
    .then((axiosResponse: AxiosResponse<{data: RefreshTokenResponse}>) => {
      const response = axiosResponse.data.data;
      console.log('response', response);
      return {
        newAuthToken: response.auth_token,
        newRefreshToken: response.refresh_token,
      };
    })
    .catch((error) => {
      console.log('Refresh token is invalid, logging out...');
      logout();
      return Promise.reject(error);
    });
}

apiClient.interceptors.response.use(undefined, async (error) => {
  console.log('error', error);
  const originalRequest = error.config;
  if (originalRequest && error.response && error.response.status === 401) {
    if (originalRequest.url.includes('/auth_tokens')) {
      console.log('Refresh token is invalid, logging out...');
      logout();
      return Promise.reject(error);
    }

    if (!isRefreshing) {
      isRefreshing = true;
      try {
        console.log('Server responded 401 - Expired JWT Token');
        const currentRefreshToken = await LocalStorage.getRefreshToken();
        if (currentRefreshToken) {
          const {newAuthToken, newRefreshToken} = await refreshToken(currentRefreshToken);
          LocalStorage.setAuthToken(newAuthToken);
          LocalStorage.setRefreshToken(newRefreshToken);

          originalRequest.headers.Authorization = `Bearer ${newAuthToken}`;
          processQueue(null, newAuthToken);
          return axios(originalRequest);
        } else {
          logout();
        }
      } catch (e) {
        processQueue(e, null);
        logout();
      } finally {
        isRefreshing = false;
      }
    }

    return new Promise((resolve, reject) => {
      failedQueue.push({resolve, reject});
    })
      .then((token) => {
        originalRequest.headers.Authorization = `Bearer ${token}`;
        return axios(originalRequest);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  return Promise.reject(error);
});
