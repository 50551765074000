import React, {useEffect, useState} from 'react';
import {Spin} from 'antd';
import {useUser} from '../UserContext';

const Home: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const {user} = useUser();

  console.log('context: ', user);

  useEffect(() => {
    const fetchProfile = async () => {
      setLoading(true);
      try {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  if (loading) {
    return (
      <Spin style={{justifyContent: 'center', flex: 1, marginTop: 150}} tip="Loading" size="large">
        <div className="content" />
      </Spin>
    );
  }

  return (
    <div className={'container'}>
      <h2 style={{marginBottom: 50, marginLeft: -5}}>Home</h2>
    </div>
  );
};

export default Home;
