export const calculateMgPlus = (E: number, Q?: number, unitClaim?: string, active?: number): number | undefined => {
  if (!Q || !unitClaim || !active) {
    return 0;
  }
  const baseValue = (Q * 100) / active + (((Q * 100) / active) * E) / 100;

  if (unitClaim === 'mg') {
    return parseFloat(baseValue.toFixed(2));
  } else if (unitClaim === 'ug') {
    return parseFloat((baseValue / 1000).toFixed(6));
  } else {
    return;
  }
};

export const calculatePercentageFormula = (mgPlus?: number, totalMgPlus?: number): number | undefined => {
  if (!mgPlus || !totalMgPlus || totalMgPlus === 0) {
    return;
  }

  return parseFloat(((mgPlus * 100) / totalMgPlus).toFixed(2));
};

export const calculatePercentageVRN = (
  Q?: number,
  vrn?: number,
  unitClaim?: string,
  unitVrn?: string,
): number | string => {
  if (vrn === 0) {
    return '--';
  }

  if (!Q || !vrn) {
    return '--';
  }

  let QInVrnUnits = Q;

  if (unitClaim === 'mg' && unitVrn === 'ug') {
    QInVrnUnits = Q * 1000;
  } else if (unitClaim === 'ug' && unitVrn === 'mg') {
    QInVrnUnits = Q / 1000;
  } else if (unitClaim === 'mg' && unitVrn === 'g') {
    QInVrnUnits = Q / 1000;
  } else if (unitClaim === 'ug' && unitVrn === 'g') {
    QInVrnUnits = Q / 1000000;
  }

  return parseFloat(((QInVrnUnits / vrn) * 100).toFixed(2));
};

export const calculateMissingMg = (dosage: number, totalMgPlus: number): number => {
  console.log('debug dosage', dosage);
  console.log('debug totalMgPlus', totalMgPlus);
  if (totalMgPlus === 0 || isNaN(totalMgPlus)) {
    return dosage;
  }
  if (!totalMgPlus) {
    return dosage;
  }

  return dosage - totalMgPlus;
};
