import React from 'react';
import {Table, TablePaginationConfig} from 'antd';
import type {ColumnsType} from 'antd/es/table';
import {IngredientDataType, ingredientItem, ingredientsColumn} from '../models/column';

const data: IngredientDataType[] = [];
for (let i = 0; i < 100; i++) {
  data.push({...ingredientItem, id: i});
}

const CustomTable: React.FC<{
  columns?: ColumnsType<IngredientDataType>;
  dataSource?: IngredientDataType[];
  pagination?: false | TablePaginationConfig;
  loading?: boolean;
}> = ({columns = ingredientsColumn, dataSource = data, pagination = false, loading = false}) => {
  return (
    <Table scroll={{y: 555}} columns={columns} dataSource={dataSource} pagination={pagination} loading={loading} />
  );
};

export default CustomTable;
